import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface HealthCheckStatus {
  status: string;
}

@Injectable({
  providedIn: 'root',
})
export class HealthCheckApiService {
  private baseUrl = `${environment.apiUrl}/status`;

  constructor(private readonly httpClient: HttpClient) {}

  getHealthCheckStatus(): Observable<HealthCheckStatus> {
    return this.httpClient
      .get<HealthCheckStatus>(`${this.baseUrl}`)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          of({ status: $localize`Unavailable (${error.statusText})` })
        )
      );
  }
}
